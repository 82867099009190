.react-grid-Container {
  .react-grid-Header {
    background: transparent;
    box-shadow: none;
    z-index: 1;
  }

  .react-grid-HeaderRow {
    // width: unset !important; 
    overflow-x: visible !important; 
    min-height: unset !important;
  }
  
  .react-grid-HeaderCell,
  .react-grid-Cell {
    background: transparent;
    border: none;
    padding: 0 10px;

    @include themify($themes) {
      border-bottom: 1px solid themed("colorBorder");
      color: themed("colorText");
    }
  }

  .react-grid-Cell {
    &.ActionCell {
      display: flex;
      align-items: center;
      .rdg-cell-action {
        height: unset !important;
        float: unset !important;
        .btn {
          margin: 0;
        }
      }
    }
    &:focus {
      outline: 1px solid $color-blue !important;
    }
  }

  .react-grid-Cell--locked:last-of-type,
  .react-grid-HeaderCell--locked {
    @include directify($directions) {
      #{directed('border-right')}: none;
    }

    @include themify($themes) {
      background-color: themed("colorBackground");
    }
  }

  .react-grid-HeaderCell {
    & > * {
      transform: translateY(-50%);
      top: 50%;
      position: absolute;
    }
  }

  .react-grid-Grid,
  .react-grid-Main,
  .react-grid-Canvas {
    border: none;
    background: transparent;
  }

  .react-grid-Main {
    outline: none;
  }

  .react-grid-Row {
    &:last-child {
      .react-grid-Cell {
        border-bottom: none;
      }
    }

    &:hover {
      .react-grid-Cell {
        background-color: transparent;
      }
    }
  }

  .react-grid-Canvas,
  .react-grid-Viewport {
    position: relative !important;
  }

  .react-grid-Viewport {
    top: 0 !important;
    direction: ltr;
  }

  .react-grid-Canvas {
    height: fit-content !important;
    overflow-y: visible !important;
  }

  .react-grid-Cell input.editor-main {
    border-radius: 0;
    background: transparent;

    @include themify($themes) {
      background-color: themed("colorBackground");
    }

    &:focus {
      border: none;
      outline: 1px solid $color-blue;
    }
  }

  .pull-right {
    position: absolute;
    @include directify($directions) {
      #{directed('right')}: -20px;
    }
    font-size: 10px;
    top: 2px;
  }

  .react-grid-HeaderCell-sortable {
    .pull-right:before {
      content: "";
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'><path fill='#646777' d='M9,3L5,7H8V14H10V7H13M16,17V10H14V17H11L15,21L19,17H16Z' /></svg>");
      position: absolute;
      width: 18px;
      height: 16px;
      @include directify($directions) {
        #{directed('right')}: -6px;
      }
      top: 0;

      @include themify($themes) {
        filter: themed("imgInvert");
      }
    }
    &.react-grid-HeaderCell-sortable--descending,
    &.react-grid-HeaderCell-sortable--ascending {
      .pull-right:before {
        visibility: hidden;
      }
    }
  }

  .react-grid-checkbox-container {
    .react-grid-checkbox {
      display: none;
    }

    .react-grid-checkbox-label {
      width: 18px;
      height: 18px;
      transition: all 0.3s;
      margin-top: 5px;
      position: relative;

      @include themify($themes) {
        border: 1px solid themed("colorFieldsBorder");
      }

      &:hover {
        border-color: $color-accent;
      }

      &:before {
        border: none;
        background: transparent;
        font-weight: 400;
      }
    }

    .react-grid-checkbox:checked + .react-grid-checkbox-label {
      border-color: $color-accent;

      &:before {
        position: absolute;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'><path fill='cyan' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' /></svg>");
        box-shadow: none;
        height: 14px;
        width: 14px;
        display: block;
        top: 1px;
        @include directify($directions) {
          #{directed('left')}: 1px;
        }
      }
    }
  }

  .react-grid-Row.row-selected,
  .react-grid-Row .row-selected {
    @include themify($themes) {
      background-color: themed("colorHover");
    }
  }
}
